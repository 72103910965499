import { Injectable } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DefaulRoleCreationService {

  constructor(private http:HttpService) { }

  getTgdsProductList(url):Observable<any>{
    return this.http.getService(url);
  }

  addTgdsDefaultRole(url,obj):Observable<any>{
    return this.http.postService(url,obj)
  }
  
  checkBranchCodeRegistered(url):Observable<any>{
    return this.http.getService(url);
  }
  getCustomRoleList(url):Observable<any>{
    return this.http.getService(url);
    }
  getSaleProductList(url):Observable<any>{
    return this.http.getService(url);
  }


 addMoreProductInsideCustomRole(url,obj):Observable<any>{
    return this.http.postService(url,obj)
  }

  deleteProdutsFromCustomRole(url,obj):Observable<any>{
    return this.http.postService(url,obj)
  }

  checkCustomRoleIsAlreadyRegistered(url){
    return this.http.getService(url);
  }

}
