import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactPersonDesignationService {

  constructor() { }

  designation=[
    {
      value:'Manager',label:'Manager'
    },
    {
      value:'Director',label:'Director'
    },
    {
      value:'HR',label:'HR'
    },
    {
      value:'Other',label:'Other'
    },
    {
      value:'***',label:'***'
    },
  ]
}
